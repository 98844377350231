export default class Queue {
  constructor(name) {
    this.name = name;
    if (!window.queues) window.queues = new Map();
    if (!window.queues.has(name)) window.queues.set(name, []);
  }

  async exec(fn, ...args) {
    const isFirst = window.queues.get(this.name).length === 0;
    if (!isFirst)
      await new Promise(resolve => window.queues.get(this.name).push(resolve));
    else new Promise(resolve => window.queues.get(this.name).push(resolve));
    const res = await fn(...args);
    if (isFirst) window.queues.get(this.name).shift();
    if (window.queues.get(this.name).length)
      window.queues.get(this.name).shift()();
    return res;
  }

  clear() {
    window.queues.get(this.name).clear();
  }
}
