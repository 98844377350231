<template>
  <div class="fill-height">
    <Calendar
      @change="changeView"
      :fields="fields"
      :query="createQuery(filters)"
    />
  </div>
</template>

<script>
import Calendar from "@/components/Calendar";
import model from "@/models/eventAndEventSource";
import { getValueOfObj } from "@/tools/functions";

export default {
  data: function() {
    return {
      filters: {}
    };
  },
  components: {
    Calendar
  },
  methods: {
    createQuery(filters) {
      return Reflect.ownKeys(filters)
        .filter(key => key !== "__ob__")
        .map(field => {
          if (filters[field] === undefined) return undefined;
          switch (model.schema[field].type) {
            case Date:
            case Number:
              return {
                field,
                operator: model.schema[field].filterOperator || "bt",
                value: Array.isArray(filters[field])
                  ? filters[field].map(a => +a)
                  : [+filters[field], null]
              };
            case Array:
              return {
                field,
                operator: model.schema[field].filterOperator || "eq",
                value: filters[field]
              };
            default:
              if (
                model.schema[field].multiple ||
                model.schema[field].multipleFilter
              ) {
                return {
                  field,
                  operator: model.schema[field].filterOperator || "eq",
                  value: filters[field]
                };
              }
              return {
                field,
                operator: model.schema[field].filterOperator || "eq",
                value: [filters[field]]
              };
          }
        })
        .filter(key => key);
    },
    changeView(val) {
      const { filters } = val;
      this.filters = filters;
    }
  },
  computed: {
    fields() {
      return Reflect.ownKeys(model.schema)
        .sort(
          (a, b) =>
            (model.schema[a].filterOrder || Infinity) -
            (model.schema[b].filterOrder || Infinity)
        )
        .filter(key =>
          [
            "eventGroup",
            "area",
            "actors",
            "scopes",
            "isMainSubject",
            "title"
          ].includes(key)
        )
        .map(key => ({
          text: this.$vuetify.lang.t(
            `$vuetify.${model.i18nPath}.${model.schema[key].name || key}`
          ),
          value: key,
          ...getValueOfObj(model.schema[key], this.filters)
        }));
    }
  }
};
</script>

<style lang="scss" scoped></style>
