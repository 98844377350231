<template>
  <div v-if="!event" class="underThePage event" :key="index"></div>
  <v-tooltip
    v-model="tooltipFlag"
    :key="event._id"
    v-else-if="!event.fromPrevious && (index < 3 || event.arrayLength === 4)"
    color="transparent"
    top
    z-index="10"
    :disabled="deleteMode"
  >
    <template v-slot:activator="{ attrs }">
      <v-btn
        v-bind="attrs"
        :class="[
          'event',
          'justify-start',
          {
            toNextWeek: event.days + idx - 1 > 7,
            hover: tooltipFlag,
            selectForDeleted: selectedEvents.includes(event._id)
          }
        ]"
        :data-days="event.days"
        :data-event-id="event._id"
        depressed
        :style="{
          width: `calc(${
            event.days + idx - 1 < 8 ? event.days * 100 - 5 : (8 - idx) * 100
          }% + ${event.days + idx < 8 ? event.days - 1 : 7 - idx}px)`,
          color: event.textColor,
          backgroundColor: event.color
        }"
        @click="gotoEvent(idx, row - 1, event._id)"
        @mouseover="showTooltip(event)"
        @mouseleave="e => hideTooltip(event, e)"
      >
        {{ event.title }}
      </v-btn>
    </template>
    <event-card :event="event" isTooltip />
  </v-tooltip>
  <v-btn
    :key="event._id"
    :class="[
      'event',
      'justify-start',
      'fromPreviousWeek',
      {
        toNextWeek: event.days + idx - 1 > 7,
        hover: tooltipFlag,
        selectForDeleted: selectedEvents.includes(event._id)
      }
    ]"
    :data-event-id="event._id"
    :data-days="event.days"
    depressed
    :style="{
      width: `calc(${
        event.days + idx < 8 ? event.days * 100 - 5 : (8 - idx) * 100
      }% + ${event.days + idx - 1 < 8 ? event.days - 1 : 7 - idx}px)`,
      color: event.textColor,
      backgroundColor: event.color
    }"
    v-else-if="event.fromPrevious && index < 3 && idx === 1"
    @click="gotoEvent(idx, row - 1, event._id)"
    @mouseover="showTooltip(event)"
    @mouseleave="e => hideTooltip(event, e)"
  >
    {{ event.title }}
  </v-btn>
  <div
    :key="event._id + 'null'"
    :class="[
      'event justify-start rounded-0 cursor-pointer no-shadow',
      { hover: tooltipFlag }
    ]"
    :data-days="event.days"
    :style="{
      width: event.days === 1 ? '95%' : '100%'
    }"
    v-else-if="index < 3"
    :data-event-id-extend="event._id"
    @click="gotoEvent(idx, row - 1, event._id, true)"
    @mouseover="showTooltip(event)"
    @mouseleave="e => hideTooltip(event, e)"
  />
  <v-btn
    :key="event._id + 'more'"
    :class="[
      'event justify-start grey lighten-5 teal--text more',
      {
        hover: tooltipFlag
      }
    ]"
    :data-days="event.days"
    depressed
    style="width: 95%"
    v-else-if="index === 4"
    @click="clickOnMore(idx, row - 1)"
    @mouseover="showTooltip(event)"
    @mouseleave="e => hideTooltip(event, e)"
  >
    {{
      $vuetify.lang.t(`$vuetify.pages.calendar.moreEvent`, [
        event.arrayLength - 3
      ]) | persianNumber()
    }}
  </v-btn>
</template>

<script>
import eventCard from "./eventCard.vue";

export default {
  props: {
    event: {
      type: Object,
      require: true
    },
    tooltipFlag: {
      type: Boolean,
      require: true
    },
    index: {
      type: Number,
      require: true
    },
    idx: {
      type: Number,
      require: true
    },
    deleteMode: {
      type: Boolean,
      default: false
    },
    selectedEvents: {
      type: Array,
      requried: true
    },
    row: {
      type: Number,
      require: true
    },
    id: {
      type: String,
      require: true
    }
  },
  components: {
    eventCard
  },
  methods: {
    gotoEvent(column, row, id) {
      this.$emit("gotoEvent", column, row, id);
    },
    showTooltip(event) {
      this.$emit("showTooltip", event);
    },
    hideTooltip(event, e) {
      this.$emit("hideTooltip", event, e);
    },
    clickOnMore(column, row) {
      this.$emit("clickOnMore", column, row);
    }
  }
};
</script>

<style lang="scss" scoped>
.calendar-view {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-bottom: 120px;
  }
}
.event-side {
  position: fixed;
  padding-bottom: 0 !important;
  right: 0;
  background: #43425d;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: calc(100vh - 64px);
  }
}

.event {
  height: 20px !important;
  line-height: 20px;
  min-width: 0 !important;
  margin-top: 1px;
  position: relative;
  font-size: 0.65rem;
  padding: 0 4px !important;
  &.selectForDeleted {
    position: relative;
    box-shadow: 0px 4px 6px rgba($color: red, $alpha: 0.5) !important;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      background: rgba($color: red, $alpha: 0.5);
      border-radius: 4px;
    }
  }
  &:not(.no-shadow):not(.selectForDeleted) {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15) !important;
  }
  &.hover {
    z-index: 5;
    &:not(.no-shadow) {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
      &.selectForDeleted {
        box-shadow: 0px 4px 6px rgba($color: red, $alpha: 0.5) !important;
      }
    }
  }
  &.toNextWeek {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &::after {
      content: "...";
      position: absolute;
      left: 5px;
    }
  }
  &.fromPreviousWeek {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 25px;
    &::before {
      content: "...";
      position: absolute;
      right: 5px;
      background: unset;
      left: unset;
      opacity: 1 !important;
    }
  }
}
.calendar-cell {
  $borderColor: #eaf0f4;
  width: 14.2857142857%;
  height: 120px;
  border-bottom: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  &:last-of-type {
    border-left: 1px solid $borderColor;
  }
  &.outside {
    border: none;
    background: #ededed;
  }
  &.column-title {
    background: #f5f6fa;
    font-size: 12px;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 9px;
    }
    &.isSingle {
      font-size: 12px;
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        font-size: 16px;
      }
    }
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-bottom: 1px solid $borderColor;
    border-top: 1px solid $borderColor;
    &:first-of-type {
      border-right: 1px solid $borderColor;
    }
    &:last-of-type {
      border-left: 1px solid $borderColor;
    }
  }
}
</style>
