export const scoreColorSets = [
  "#ff0000",
  "#ff4801",
  "#ff680f",
  "#ff8122",
  "#ff9737",
  "#ffab4f",
  "#ffbe6a",
  "#ffd089",
  "#ffe0ac",
  "#fff0d3",
  "#ffffff",
  "#e8f4d9",
  "#d0e8b8",
  "#badc9c",
  "#a3cf82",
  "#8dc26b",
  "#76b555",
  "#60a841",
  "#489b2e",
  "#2e8d1a",
  "#008000"
];

export default {
  path: "/dashboard/event",
  i18nPath: "pages.calendar.events",
  schema: {
    title: {
      type: String,
      required: true,
      filterOperator: "lk",
      filterOrder: -2,
      filterGrid: {
        cols: 12,
        md: 12
      }
    },
    start: {
      type: Date,
      required: true,
      hide: true,
      hideInFilter: true,
      hideDetails: true
    },
    eventGroup: {
      type: String,
      ref: "eventGroup",
      filterOrder: 1,
      multiple: false,
      multipleFilter: true,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      minCharSearch: 2,
      noChip: true,
      required: true
    },
    area: {
      type: String,
      required: true,
      filterOrder: 3,
      inputFilterGrid: {
        md: 6,
        cols: 12
      },
      enum: ["local", "region", "province", "country", "global"],
      default: "country",
      i18nPath: "pages.calendar.area",
      inputGrid: {
        md: 4
      }
    },
    duration: {
      type: Number,
      required: true,
      default: 1,
      min: 1,
      hideInFilter: true,
      inputGrid: {
        md: 4
      }
    },
    calendarType: {
      type: String,
      enum: ["Gregorian", "Jalaali"],
      i18nPath: "pages.calendar.calendarType",
      required: true,
      default: "Jalaali",
      inputGrid: {
        md: 4
      }
    },
    repetitiveType: {
      type: String,
      enum: ["none", "daily", "weekly", "monthly", "annual"],
      i18nPath: "pages.calendar.repetitiveType",
      required: true,
      hideInFilter: true,
      default: "none",
      inputGrid(item) {
        return item.repetitiveType === "none"
          ? {
              md: 12
            }
          : {
              md: 4
            };
      }
    },
    repeatPeriod: {
      type: Number,
      min: 1,
      filterOperator: "eq",
      hideInFilter: true,
      hide(item) {
        return item.repetitiveType === "none";
      },
      inputGrid: {
        md: 4
      }
    },
    end: {
      type: Date,
      view: "year",
      hideInFilter: true,
      hide(item) {
        return item.repetitiveType === "none";
      },
      locale(item) {
        return ["fa", "en"][item.calendarType === "Jalaali" ? 0 : 1];
      },
      inputGrid: {
        md: 4
      }
    },
    score: {
      type: Number,
      min: -10,
      max: 10,
      default: 0,
      required: true,
      filterGrid: {
        cols: 12,
        md: 12
      }
    },
    color: {
      type: String,
      subType: "color",
      default(item) {
        item.color = scoreColorSets[item.score ? +item.score + 10 : 10];
        return item.color;
      },
      hideInFilter: true
    },
    actors: {
      type: Array,
      ref: "actor",
      multiple: true,
      hideInBrowse: true,
      filterOrder: 4,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true
    },
    scopes: {
      type: Array,
      ref: "scope",
      multiple: true,
      filterOrder: 5,
      hideInBrowse: true,
      itemValue: "_id",
      itemText: "name",
      descriptionText: "description",
      searchField: ["name"],
      joinChar: " | ",
      minCharSearch: 2,
      smallChips: true
    },
    isMainSubject: {
      type: Boolean,
      default: false,
      filterOrder: 2,
      inputFilterGrid: {
        md: 6,
        cols: 12
      }
    },
    description: {
      type: String,
      subType: "wysiwyg",
      multiLine: true,
      filterOperator: "lk",
      filterOrder: -1,
      filterGrid: {
        cols: 12,
        md: 12
      },
      inputGrid: {
        cols: 12,
        md: 12
      }
    }
  }
};
