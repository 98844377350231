var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.event)?_c('v-card',{class:['mb-4', 'event-card', { selected: _vm.event._id === _vm.selectedEvent }],attrs:{"id":("event-" + (_vm.event._id)),"max-width":_vm.isTooltip ? 500 : undefined,"outlined":_vm.isReport}},[_c('v-list-item',[(_vm.event.isMainSubject)?_c('v-badge',{attrs:{"bordered":"","color":"info","left":"","icon":"mdi-star","overlap":""}},[_c('v-list-item-avatar',{staticClass:"ma-0 ml-3",attrs:{"color":_vm.event.color}},[_c('span',{style:({ color: _vm.event.textColor }),attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm._f("persianNumber")(_vm.score))+" ")])])],1):_c('v-list-item-avatar',{staticClass:"ma-0 ml-3",attrs:{"color":_vm.event.color}},[_c('span',{style:({ color: _vm.event.textColor }),attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(_vm._f("persianNumber")(_vm.score))+" ")])]),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title",domProps:{"textContent":_vm._s(_vm.event.title)}}),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( ("$vuetify.pages.calendar.calendarType." + (_vm.event.calendarType)) ))+" ")])],1),_c('v-list-item-action',[(_vm.canEdit && !_vm.unApproved && !_vm.isReport)?_c('v-menu',{attrs:{"offset-y":"","origin":"center center","transition":"scroll-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"grey darken-1"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1529233829)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":_vm.editEvent}},[_c('v-list-item-icon',{staticClass:"ml-1 my-auto"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])],1),_c('v-list-item',{on:{"click":_vm.deleteEvent}},[_c('v-list-item-icon',{staticClass:"ml-1 my-auto"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])],1)],1)],1):(_vm.isReport)?_c('div',[_vm._v(" "+_vm._s(_vm._f("persianNumber")(_vm.dateTitle))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"pb-4"},[_c('div',{staticClass:"pa-4 d-flex"},[_c('span',{staticClass:"text-caption ml-1"},[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.pages.calendar.eventGroup"))+": ")]),_c('span',{staticClass:"text-caption font-weight-bold flex-fill"},[_vm._v(_vm._s(_vm.event.eventGroup.name))]),(!_vm.isTooltip && _vm.event.allDays > 1)?_c('span',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm._f("persianNumber")((_vm.event.allDays - _vm.event.days + 1)))+" / "+_vm._s(_vm._f("persianNumber")(_vm.event.allDays))+" ")]):_vm._e()]),_c('div',{staticClass:"px-3"},[_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.pages.calendar.events.area"))+": ")]),_c('strong',[_vm._v(_vm._s(_vm.$vuetify.lang.t(("$vuetify.pages.calendar.area." + (_vm.event.area)))))])]),(_vm.isTooltip)?_c('v-clamp',{class:[
        'text-body-1 grey--text text--darken-2 px-4 py-0 description',
        { unApproved: _vm.unApproved }
      ],attrs:{"max-lines":3},domProps:{"innerHTML":_vm._s(_vm.event.description)}}):(_vm.unApproved || _vm.event.description)?_c('p',{class:[
        'text-body-1 grey--text text--darken-2 px-4 py-0 description ma-0',
        { unApproved: _vm.unApproved, isReport: _vm.isReport }
      ],domProps:{"innerHTML":_vm._s(_vm.event.description)}}):_vm._e(),(_vm.event.actors && _vm.event.actors.length)?_c('div',{staticClass:"pa-4 pt-0"},[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.pages.calendar.actors"))+": ")]),_c('div',{class:['actors', { unApproved: _vm.unApproved }]},[_c('v-chip-group',{attrs:{"mandatory":_vm.isTooltip,"show-arrows":false,"column":!_vm.isTooltip}},_vm._l((_vm.event.actors),function(actor){return _c('v-chip',{key:actor._id,staticClass:"mb-1 ml-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(actor.name)+" ")])}),1)],1)]):_vm._e(),(_vm.event.scopes && _vm.event.scopes.length)?_c('div',{staticClass:"pa-4 pt-0"},[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.pages.calendar.scopes"))+": ")]),_c('div',{class:['scops', { unApproved: _vm.unApproved }]},_vm._l((_vm.event.scopes),function(scope){return _c('v-chip',{key:scope._id,staticClass:"mb-1 ml-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(scope.name)+" ")])}),1)]):_vm._e()],1),(_vm.hasAcction)?_c('v-divider',{staticClass:"mx-4"}):_vm._e(),(_vm.hasAcction)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading.reject,"color":"red","text":""},on:{"click":_vm.reject}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.pages.calendar.reject"))+" ")]),_c('v-btn',{staticClass:"white--text",attrs:{"loading":_vm.loading.approve,"color":"cyan"},on:{"click":_vm.approve}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.pages.calendar.approve"))+" ")])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }