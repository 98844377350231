<template>
  <v-card
    v-if="event"
    :id="`event-${event._id}`"
    :max-width="isTooltip ? 500 : undefined"
    :outlined="isReport"
    :class="['mb-4', 'event-card', { selected: event._id === selectedEvent }]"
  >
    <v-list-item>
      <v-badge
        v-if="event.isMainSubject"
        bordered
        color="info"
        left
        icon="mdi-star"
        overlap
      >
        <v-list-item-avatar :color="event.color" class="ma-0 ml-3">
          <span :style="{ color: event.textColor }" dir="ltr">
            {{ score | persianNumber() }}
          </span>
        </v-list-item-avatar>
      </v-badge>
      <v-list-item-avatar v-else :color="event.color" class="ma-0 ml-3">
        <span :style="{ color: event.textColor }" dir="ltr">
          {{ score | persianNumber() }}
        </span>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          class="title"
          v-text="event.title"
        ></v-list-item-title>

        <v-list-item-subtitle>
          {{
            $vuetify.lang.t(
              `$vuetify.pages.calendar.calendarType.${event.calendarType}`
            )
          }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-menu
          offset-y
          origin="center center"
          transition="scroll-y-transition"
          v-if="canEdit && !unApproved && !isReport"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="grey darken-1">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="editEvent">
              <v-list-item-icon class="ml-1 my-auto">
                <v-icon small>mdi-pencil</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                $vuetify.lang.t(`$vuetify.edit`)
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteEvent">
              <v-list-item-icon class="ml-1 my-auto">
                <v-icon small>mdi-delete</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{
                $vuetify.lang.t(`$vuetify.delete`)
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else-if="isReport">
          {{ dateTitle | persianNumber() }}
        </div>
      </v-list-item-action>
    </v-list-item>
    <div class="pb-4">
      <div class="pa-4 d-flex">
        <span class="text-caption ml-1"
          >{{ $vuetify.lang.t(`$vuetify.pages.calendar.eventGroup`) }}:
        </span>
        <span class="text-caption font-weight-bold flex-fill">{{
          event.eventGroup.name
        }}</span>
        <span v-if="!isTooltip && event.allDays > 1" class="text-caption">
          {{ (event.allDays - event.days + 1) | persianNumber() }} /
          {{ event.allDays | persianNumber() }}
        </span>
      </div>
      <div class="px-3">
        <span class="ml-1">
          {{ $vuetify.lang.t(`$vuetify.pages.calendar.events.area`) }}:
        </span>
        <strong>{{
          $vuetify.lang.t(`$vuetify.pages.calendar.area.${event.area}`)
        }}</strong>
      </div>
      <v-clamp
        :class="[
          'text-body-1 grey--text text--darken-2 px-4 py-0 description',
          { unApproved }
        ]"
        v-if="isTooltip"
        v-html="event.description"
        :max-lines="3"
      >
      </v-clamp>
      <p
        :class="[
          'text-body-1 grey--text text--darken-2 px-4 py-0 description ma-0',
          { unApproved, isReport }
        ]"
        v-else-if="unApproved || event.description"
        v-html="event.description"
      />
      <div class="pa-4 pt-0" v-if="event.actors && event.actors.length">
        <div class="text-caption">
          {{ $vuetify.lang.t(`$vuetify.pages.calendar.actors`) }}:
        </div>
        <div :class="['actors', { unApproved }]">
          <v-chip-group
            :mandatory="isTooltip"
            :show-arrows="false"
            :column="!isTooltip"
          >
            <v-chip
              small
              class="mb-1 ml-1"
              v-for="actor in event.actors"
              :key="actor._id"
            >
              {{ actor.name }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
      <div class="pa-4 pt-0" v-if="event.scopes && event.scopes.length">
        <div class="text-caption">
          {{ $vuetify.lang.t(`$vuetify.pages.calendar.scopes`) }}:
        </div>
        <div :class="['scops', { unApproved }]">
          <v-chip
            small
            class="mb-1 ml-1"
            v-for="scope in event.scopes"
            :key="scope._id"
          >
            {{ scope.name }}
          </v-chip>
        </div>
      </div>
    </div>
    <v-divider v-if="hasAcction" class="mx-4"></v-divider>
    <v-card-actions v-if="hasAcction">
      <v-spacer />
      <v-btn :loading="loading.reject" color="red" text @click="reject">
        {{ $vuetify.lang.t(`$vuetify.pages.calendar.reject`) }}
      </v-btn>
      <v-btn
        :loading="loading.approve"
        color="cyan"
        class="white--text"
        @click="approve"
      >
        {{ $vuetify.lang.t(`$vuetify.pages.calendar.approve`) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { format, isSameDay } from "@/tools/date-library";
export default {
  props: {
    event: {
      type: Object,
      required: true
    },
    selectedEvent: String,
    unApproved: Boolean,
    isReport: Boolean,
    loading: Object,
    isTooltip: Boolean
  },
  computed: {
    calendarType: {
      get() {
        return this.$store.getters["global/getCalendarType"];
      }
    },
    dateTitle() {
      return isSameDay[this.calendarType](this.event.start, this.event.end)
        ? format[this.calendarType](this.event.start, "yyyy/MM/dd")
        : `${format[this.calendarType](
            this.event.start,
            "yyyy/MM/dd"
          )} - ${format[this.calendarType](this.event.end, "yyyy/MM/dd")}`;
    },
    hasAcction() {
      return (
        this.unApproved && this.$store.getters["auth/getUserRole"] === "admin"
      );
    },
    canEdit() {
      return (
        !this.isTooltip && this.$store.getters["auth/getUserRole"] === "admin"
      );
    },
    score() {
      return this.event.score <= 0 ? this.event.score : `+${this.event.score}`;
    }
  },
  methods: {
    editEvent() {
      this.$emit("edit", this.event);
    },
    deleteEvent() {
      this.$emit("delete", this.event);
    },
    reject() {
      this.$emit("reject", this.event);
    },
    approve() {
      this.$emit("approve", this.event);
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes eventBlink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.event-card {
  break-inside: avoid;
  &.selected {
    animation: eventBlink 1s linear;
    animation-delay: 0.5s;
  }
}
.scops,
.actors {
  &.unApproved {
    height: 50px;
    overflow: auto;
  }
}
.description {
  &.unApproved {
    height: 120px;
  }
  max-height: 120px;
  overflow: auto;
  &.isReport {
    max-height: none;
    overflow: hidden;
  }
}
.title {
  font-size: 0.9rem !important;
  overflow: visible;
  white-space: pre-wrap;
}
</style>
