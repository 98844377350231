import api from "./api";
import md5 from "md5";

export const calendarService = {
  getEvents: async (query, start, end) => {
    const key = md5(JSON.stringify({ query, start, end }));
    !window.eventsCatch && (window.eventsCatch = {});
    if (window.eventsCatch[key]) {
      return window.eventsCatch[key];
    }
    window.eventsCatch[
      key
    ] = api.post(`dashboard/event/getEvents/${start}/${end}`, { query: query });
    return window.eventsCatch[key];
  },
  addEvents: async data => api.post("dashboard/event", data),
  editEvent: async (id, type, data) =>
    api.put(`dashboard/event/${id}/${type}`, data),
  deleteEvent: async (id, type) => api.delete(`dashboard/event/${id}/${type}`),
  getUnApproved: async (page, perPage) =>
    api.post(
      `dashboard/eventSource/unApproved/${(page - 1) * perPage}/${perPage}`
    ),
  approvedEvent: async id => api.patch(`dashboard/eventSource/approve/${id}`),
  rejectEvent: async id => api.delete(`dashboard/eventSource/${id}`),
  uploadXLSX: async (file, uploadProgress) =>
    api.post(`dashboard/event/uploadXLSX`, file, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: function(progressEvent) {
        uploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
      }
    })
};
