<template>
  <v-row class="flex-column-reverse flex-md-row ma-0" justify-md="end">
    <v-col
      cols="12"
      md="3"
      role="side"
      class="pa-0 event-side"
      style="z-index: 2"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <sidebar
        :events="sortedEvents[inputDate.getTime()]"
        :inputDate="inputDate"
        :selectedEvent="selectedEvent"
        @change="changeSidebar"
      />
    </v-col>
    <v-col cols="12" md="9" class="pa-2 pa-md-4 overflow-y-auto fill-height">
      <div class="fill-height calendar-view d-flex flex-column">
        <div
          class="d-flex justify-center justify-md-space-between text-center align-center pa-o pa-md-2  flex-wrap mb-1"
        >
          <div class="d-flex align-center">
            <v-btn icon @click="goPrevious">
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
            <transition
              :enter-active-class="
                animationDirection ? 'animated fadeInUp' : 'animated fadeInDown'
              "
              :leave-active-class="
                animationDirection
                  ? 'animated fadeOutUp'
                  : 'animated fadeOutDown'
              "
              mode="out-in"
            >
              <div :key="title" class="animation-speed-4 calendar-title">
                {{ title | persianNumber() }}
              </div>
            </transition>
            <v-btn icon @click="goNext">
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
          </div>
          <v-btn
            class="btn btn-primary"
            @click="showGoto = true"
            text
            color="primary"
          >
            {{ $vuetify.lang.t(`$vuetify.pages.calendar.gotoDate`) }}
          </v-btn>
          <input
            type="text"
            :value="inputDate"
            @input="updateInputDate"
            id="my-custom-editable-input"
            v-show="false"
          />
          <date-picker
            :value="inputDate"
            @input="updateInputDate"
            format="jYYYY/jMM/jDD"
            element="my-custom-editable-input"
            :editable="true"
            :show="showGoto"
            @close="showGoto = false"
            :locale="locale"
            :convertNumbers="locale === 'fa'"
          />
          <v-dialog v-model="filterDialog" width="800">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <transition
                  enter-active-class="animated fadeInDown"
                  leave-active-class="animated fadeOutUp"
                  mode="out-in"
                >
                  <v-icon
                    key="mdi-filter-outline"
                    v-if="!hasFilter"
                    class="animation-speed-4"
                  >
                    mdi-filter-outline
                  </v-icon>
                  <v-icon key="mdi-filter" v-else class="animation-speed-4">
                    mdi-filter
                  </v-icon>
                </transition>
              </v-btn>
            </template>

            <v-card tag="form" @submit.prevent="applyFilter" novalidate>
              <v-card-title class="headline">
                {{ $vuetify.lang.t(`$vuetify.pages.calendar.filters`) }}
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col
                    v-for="field in fields"
                    :cols="
                      (field.inputFilterGrid && field.inputFilterGrid.cols) ||
                        12
                    "
                    :sm="field.inputFilterGrid && field.inputFilterGrid.sm"
                    :md="
                      (field.inputFilterGrid && field.inputFilterGrid.md) || 12
                    "
                    :lg="field.inputFilterGrid && field.inputFilterGrid.lg"
                    :xl="field.inputFilterGrid && field.inputFilterGrid.xl"
                    :key="field.value"
                    class="py-2"
                  >
                    <filterFields
                      v-model="filters[field.value]"
                      :label="field.text"
                      :fieldModel="field"
                    />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="filterDialog = false">
                  {{ $vuetify.lang.t(`$vuetify.cancel`) }}
                </v-btn>
                <v-btn color="primary" text type="submit">
                  {{ $vuetify.lang.t(`$vuetify.apply`) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <transition
            enter-active-class="animated fadeInDown"
            leave-active-class="animated fadeOutUp"
            mode="out-in"
          >
            <v-btn
              icon
              v-if="hasFilter"
              color="red"
              @click="clearFilters"
              class="animation-speed-4"
            >
              <v-icon>mdi-filter-remove-outline</v-icon>
            </v-btn>
          </transition>
          <v-badge
            :value="selectedEvents.length"
            :content="deleteBadge"
            color="red"
            overlap
          >
            <v-btn
              icon
              :color="deleteMode ? 'red' : undefined"
              @click="onDelete"
            >
              <transition
                enter-active-class="animated fadeInDown"
                leave-active-class="animated fadeOutUp"
                mode="out-in"
              >
                <v-icon
                  key="mdi-delete-outline"
                  v-if="!deleteMode"
                  class="animation-speed-4"
                >
                  mdi-delete-outline
                </v-icon>
                <v-icon key="mdi-delete" v-else class="animation-speed-4">
                  mdi-delete
                </v-icon>
              </transition>
            </v-btn>
          </v-badge>
          <v-dialog v-model="deleteBulkDialog" max-width="300px">
            <v-card>
              <v-card-title>{{
                $vuetify.lang.t(`$vuetify.delete`)
              }}</v-card-title>
              <v-card-text>{{
                $vuetify.lang.t(`$vuetify.pages.calendar.deleteBulkMessage`)
              }}</v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="deleteBulkDialog = false">
                  {{ $vuetify.lang.t(`$vuetify.cancel`) }}
                </v-btn>
                <v-btn text @click="reSelect">
                  {{ $vuetify.lang.t(`$vuetify.pages.calendar.reSelect`) }}
                </v-btn>
                <v-btn color="red" dark @click="deleteBulk">
                  {{ $vuetify.lang.t(`$vuetify.deleteAll`) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer />
          <v-btn-toggle v-model="type" class="ml-1">
            <v-btn small outlined>
              {{ $vuetify.lang.t(`$vuetify.scope.monthly`) }}
            </v-btn>
            <v-btn small outlined>
              {{ $vuetify.lang.t(`$vuetify.scope.seasonal`) }}
            </v-btn>
            <v-btn small outlined>
              {{ $vuetify.lang.t(`$vuetify.scope.annual`) }}
            </v-btn>
          </v-btn-toggle>
          <v-btn-toggle v-model="calendarType">
            <v-btn small outlined>
              {{
                $vuetify.lang.t(`$vuetify.pages.calendar.calendarType.Jalaali`)
              }}
            </v-btn>
            <v-btn small outlined>
              {{
                $vuetify.lang.t(
                  `$vuetify.pages.calendar.calendarType.Gregorian`
                )
              }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <v-row>
          <v-col
            v-for="month in months"
            cols="12"
            :md="months.length === 1 ? undefined : 4"
            :key="month.id"
            class="pt-0"
          >
            <month-view
              :query="query"
              :id="month.id"
              :year="month.year"
              :month="month.month"
              :inputDate="inputDate"
              :isSingle="months.length === 1"
              :deleteMode="deleteMode"
              :calendarType="calendarType"
              :selectedEvents.sync="selectedEvents"
              :calnedarView="type"
              @goPrevious="goPrevious"
              @goNext="goNext"
              @clickOnDay="clickOnDay"
              @clickOnMore="clickOnMore"
              @gotoEvent="gotoEvent"
              @updateSortedEvents="updateSortedEvents"
            />
          </v-col>
        </v-row>
      </div>
    </v-col>
    <swipeable-bottom-sheet
      v-if="$vuetify.breakpoint.smAndDown"
      ref="swipeableBottomSheet"
      backgroundColor="#43425d"
      barColor="rgba(255,255,255,0.5)"
    >
      <sidebar
        :events="sortedEvents[inputDate.getTime()]"
        :inputDate="inputDate"
        :selectedEvent="selectedEvent"
        :isMobile="true"
        @change="changeSidebar"
      />
    </swipeable-bottom-sheet>
    <v-dialog
      v-if="sortedEvents && sortedEvents[inputDate.getTime()]"
      v-model="moreEventDialog"
      max-width="300"
    >
      <v-card>
        <v-card-title>
          {{ inputDate | jMoment("jDD jMMMM jYYYY") | persianNumber() }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <template v-for="event in sortedEvents[inputDate.getTime()]">
            <v-tooltip
              :key="event._id"
              color="transparent"
              top
              z-index="10"
              :disabled="deleteMode"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :class="[
                    'event',
                    'justify-start',
                    {
                      selectForDeleted: selectedEvents.includes(event._id)
                    }
                  ]"
                  :data-days="event.days"
                  :data-event-id="event._id"
                  :style="{
                    width: '100%',
                    color: event.textColor,
                    backgroundColor: event.color
                  }"
                  @click="gotoEvent(event._id)"
                >
                  <span style="white-space: normal;">
                    {{ event.title }}
                  </span>
                </v-btn>
              </template>
              <event-card :event="event" isTooltip />
            </v-tooltip>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  setMonth,
  getMonth,
  getYear,
  format,
  setYear
} from "@/tools/date-library";
import sidebar from "./sidebar";
import SwipeableBottomSheet from "@/components/SwipeableBottomSheet";
import MonthView from "./monthView.vue";
import filterFields from "@/components/DataTable/filterFields";
import { deepEqual } from "fast-equals";
import { persianNumber } from "@/plugins/filters";
import { calendarService } from "@/services/calendar";

export default {
  props: {
    events: {
      type: Array
    },
    fields: Array,
    query: Array
  },
  data: function() {
    return {
      moreEventDialog: false,
      selectedEvent: undefined,
      sortedEvents: [],
      animationDirection: false,
      type: +localStorage.getItem("calendarView"),
      showGoto: false,
      filters: {},
      filterDialog: false,
      deleteMode: false,
      selectedEvents: [],
      deleteBulkDialog: false
    };
  },
  components: {
    sidebar,
    SwipeableBottomSheet,
    MonthView,
    filterFields
  },
  watch: {
    "$route.query"() {
      this.filters = { ...this.filters, ...this.$route.query };
      this.$emit("change", {
        filters: this.filters
      });
    },
    type(val) {
      localStorage.setItem("calendarView", val);
    },
    events: {
      handler(val) {
        console.log(`event length: ${val.length}`);
      },
      deep: true
    }
  },
  computed: {
    deleteBadge() {
      return persianNumber(this.selectedEvents.length);
    },
    inputDate: {
      get() {
        return this.$store.getters["global/getInputDate"];
      },
      set(val) {
        this.$store.commit("global/setInputDate", val);
      }
    },
    hasFilter() {
      return Reflect.ownKeys(this.filters).filter(key => key !== "__ob__")
        .length;
    },
    calendarType: {
      get() {
        return this.$store.getters["global/getCalendarType"];
      },
      set(val) {
        this.$store.commit("global/setCalendarType", val);
      }
    },
    locale() {
      return ["fa", "en"][this.calendarType];
    },
    title() {
      switch (this.type) {
        case 2:
          return getYear[this.calendarType](this.inputDate);
        case 1:
          // string must change to i18n
          return (
            ["بهار", "تابستان", "پاییز", "زمستان"][
              Math.floor(getMonth[this.calendarType](this.inputDate) / 3)
            ] +
            "  " +
            format[this.calendarType](this.inputDate, "yyyy")
          );
        default:
          return format[this.calendarType](this.inputDate, "MMMM yyyy");
      }
    },
    months() {
      const year = getYear[this.calendarType](this.inputDate);
      const month = getMonth[this.calendarType](this.inputDate);
      const startOfSeason = Math.floor(month / 3) * 3;
      switch (this.type) {
        case 2:
          return new Array(12).fill().map((i, month) => ({
            year,
            month,
            id: `${year}-${month}`
          }));
        case 1:
          return new Array(3).fill().map((i, month) => ({
            year,
            month: month + startOfSeason,
            id: `${year}-${month + startOfSeason}`
          }));
        default:
          return [
            {
              year,
              month,
              id: `${year}-${month}`
            }
          ];
      }
    },
    enterActiveClass() {
      switch (this.type) {
        case 0:
          return this.animationDirection
            ? "animated fadeInLeft"
            : "animated fadeInRight";
        case 2:
          return "";
        default:
          return "animated fade";
      }
    },
    leaveActiveClass() {
      switch (this.type) {
        case 0:
          return this.animationDirection
            ? "animated fadeInLeft"
            : "animated fadeInRight";
        case 2:
          return "";
        default:
          return "animated fade";
      }
    }
  },
  methods: {
    reSelect() {
      this.selectedEvents = [];
      this.deleteBulkDialog = false;
      this.deleteMode = false;
    },
    deleteBulk() {
      Promise.all(
        this.selectedEvents.map(id => calendarService.deleteEvent(id, "all"))
      ).then(() => {
        this.selectedEvents = [];
        this.deleteBulkDialog = false;
        this.deleteMode = false;
        this.applyFilter();
      });
    },
    onDelete() {
      if (!this.selectedEvents.length) {
        this.deleteMode = !this.deleteMode;
      } else {
        this.deleteBulkDialog = true;
      }
    },
    pushQuery() {
      if (!deepEqual(this.filters, this.$route.query)) {
        this.$router
          .replace({ ...this.$route, query: this.filters })
          .catch(e => {
            debugger;
            console.log(e);
          });
      }
    },
    clearFilters() {
      this.filters = {};
      this.pushQuery();
      this.$emit("change", {
        filters: this.filters
      });
    },
    applyFilter() {
      this.filterDialog = false;
      this.pushQuery();
      this.$emit("change", {
        filters: this.filters
      });
    },
    changeSidebar() {
      this.$emit("change", {
        filters: this.filters
      });
    },
    clickOnMore(date) {
      this.inputDate = date;
      this.moreEventDialog = true;
      this.selectedEvent = undefined;
    },
    clickOnDay(date) {
      this.inputDate = date;
      this.selectedEvent = undefined;
    },
    updateInputDate(date) {
      this.inputDate = date;
    },
    goPrevious() {
      this.animationDirection = false;
      switch (this.type) {
        case 2:
          return (this.inputDate = setYear[this.calendarType](
            this.inputDate,
            getYear[this.calendarType](this.inputDate) - 1
          ));
        case 1:
          return (this.inputDate = setMonth[this.calendarType](
            this.inputDate,
            getMonth[this.calendarType](this.inputDate) - 3
          ));
        default:
          return (this.inputDate = setMonth[this.calendarType](
            this.inputDate,
            getMonth[this.calendarType](this.inputDate) - 1
          ));
      }
    },
    goNext() {
      this.animationDirection = true;
      switch (this.type) {
        case 2:
          return (this.inputDate = setYear[this.calendarType](
            this.inputDate,
            getYear[this.calendarType](this.inputDate) + 1
          ));
        case 1:
          return (this.inputDate = setMonth[this.calendarType](
            this.inputDate,
            getMonth[this.calendarType](this.inputDate) + 3
          ));
        default:
          return (this.inputDate = setMonth[this.calendarType](
            this.inputDate,
            getMonth[this.calendarType](this.inputDate) + 1
          ));
      }
    },
    gotoEvent(id) {
      if (this.deleteMode) {
        const idx = this.selectedEvents.indexOf(id);
        if (idx > -1) {
          const _innerSeleted = [...this.selectedEvents];
          _innerSeleted.splice(idx, 1);
          this.selectedEvents = _innerSeleted;
        } else {
          this.selectedEvents = [...this.selectedEvents, id];
        }
      } else {
        document
          .querySelectorAll(`[data-event-id="${id}"]`)
          .forEach(el => this.rippleElement(el));
        const selected = document.querySelector(".event-card.selected");
        if (selected && selected.id === `event-${id}`) {
          selected.className = selected.className.replace(
            "selected",
            "no-delay"
          );
          const timer1 = setTimeout(() => {
            selected.className = selected.className + " selected";
            clearTimeout(timer1);
            const timer2 = setTimeout(() => {
              selected.className.replace("no-delay", "");
              clearTimeout(timer2);
            }, 1000);
          }, 0);
        }
        this.$nextTick(() => {
          this.selectedEvent = id;
        });
      }
    },
    rippleElement($el) {
      let ev = new Event("mousedown");
      let offset = $el.getBoundingClientRect();
      ev.clientX = offset.left + offset.width / 2;
      ev.clientY = offset.top + offset.height / 2;
      $el.dispatchEvent(ev);

      setTimeout(function() {
        $el.dispatchEvent(new Event("mouseup"));
      }, 300);
    },
    updateSortedEvents(id, events) {
      this.sortedEvents[id] = events;
    }
  },
  created() {
    this.filters = { ...this.filters, ...this.$route.query };

    this.$emit("change", {
      filters: this.filters
    });
  }
};
</script>

<style lang="scss" scoped>
.calendar-view {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-bottom: 120px;
  }
}
.event-side {
  position: fixed;
  padding-bottom: 0 !important;
  right: 0;
  background: #43425d;
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: calc(100vh - 64px);
  }
}

.event {
  min-width: 0 !important;
  height: fit-content !important;
  padding: 5px !important;
  margin-top: 5px;
  position: relative;
  &.selectForDeleted {
    position: relative;
    box-shadow: 0px 4px 6px rgba($color: red, $alpha: 0.5) !important;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      background: rgba($color: red, $alpha: 0.5);
      border-radius: 4px;
    }
  }
  &.toNextWeek {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    &::after {
      content: "...";
      position: absolute;
      left: 5px;
    }
  }
  &.fromPreviousWeek {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 25px;
    &::before {
      content: "...";
      position: absolute;
      right: 5px;
      background: unset;
      left: unset;
      opacity: 1 !important;
    }
  }
}
.calendar-title {
  width: 120px;
}
.calendar-cell {
  $borderColor: #eaf0f4;
  &.month {
    width: 14.2857142857%;
    height: 120px;
    border-bottom: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    &:last-of-type {
      border-left: 1px solid $borderColor;
    }
    &.outside {
      border: none;
      background: #ededed;
    }
  }
  &.column-title {
    background: #f5f6fa;
    font-size: 12px;
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      font-size: 16px;
    }
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-bottom: 1px solid $borderColor;
    border-top: 1px solid $borderColor;
    &:first-of-type {
      border-right: 1px solid $borderColor;
    }
    &:last-of-type {
      border-left: 1px solid $borderColor;
    }
  }
}
</style>
