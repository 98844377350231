<template>
  <v-card tag="form" @submit.prevent="onSubmit">
    <v-card-title v-if="!isMobile">
      {{
        isEdit
          ? $vuetify.lang.t(`$vuetify.pages.calendar.editEvent`)
          : $vuetify.lang.t(`$vuetify.pages.calendar.addEvent`)
      }}
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col
          v-for="field in fields"
          :cols="(field.inputGrid && field.inputGrid.cols) || 12"
          :sm="field.inputGrid && field.inputGrid.sm"
          :md="(field.inputGrid && field.inputGrid.md) || 6"
          :lg="field.inputGrid && field.inputGrid.lg"
          :xl="field.inputGrid && field.inputGrid.xl"
          :key="field.value"
          class="py-2"
        >
          <inputFields
            v-model="innerValue[field.value]"
            :label="field.text"
            :fieldModel="field"
            background-color="white"
            :default="field.default"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer />
      <v-btn type="button" v-if="!isMobile" text @click="close">
        {{ $vuetify.lang.t(`$vuetify.cancel`) }}
      </v-btn>
      <v-btn
        type="submit"
        :block="isMobile"
        depressed
        color="primary"
        :loading="loading"
      >
        {{ $vuetify.lang.t(`$vuetify.save`) }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import inputFields from "@/components/DataTable/inputFields";

export default {
  props: {
    fields: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    }
  },
  data: function() {
    return {
      innerValue: {}
    };
  },
  methods: {
    onSubmit() {
      this.$emit("submit", this.value);
    },
    close() {
      this.$emit("close");
    }
  },
  created() {
    this.innerValue = this.value;
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        this.innerValue = val;
      }
    }
  },
  components: {
    inputFields
  }
};
</script>

<style lang="scss" scoped></style>
