import {
  set as setJalaali,
  endOfMonth as endOfMonthJalaali,
  startOfWeek as startOfWeekJalaali,
  endOfWeek as endOfWeekJalaali,
  differenceInCalendarDays as differenceInCalendarDaysJalaali,
  isSameDay as isSameDayJalaali,
  getDate as getDateJalaali,
  startOfDay as startOfDayJalaali,
  endOfDay as endOfDayJalaali,
  isSameMonth as isSameMonthJalaali,
  addDays as addDaysJalaali,
  format as formatJalaali,
  setMonth as setMonthJalaali,
  getMonth as getMonthJalaali,
  startOfMonth as startOfMonthJalaali,
  startOfYear as startOfYearJalaali,
  endOfYear as endOfYearJalaali,
  getYear as getYearJalaali,
  setYear as setYearJalaali,
  addYears as addYearsJalaali,
  addMonths as addMonthsJalaali
} from "date-fns-jalali";

import {
  set as setGregorian,
  endOfMonth as endOfMonthGregorian,
  startOfWeek as startOfWeekGregorian,
  endOfWeek as endOfWeekGregorian,
  differenceInCalendarDays as differenceInCalendarDaysGregorian,
  isSameDay as isSameDayGregorian,
  getDate as getDateGregorian,
  startOfDay as startOfDayGregorian,
  endOfDay as endOfDayGregorian,
  isSameMonth as isSameMonthGregorian,
  addDays as addDaysGregorian,
  format as formatGregorian,
  setMonth as setMonthGregorian,
  getMonth as getMonthGregorian,
  startOfMonth as startOfMonthGregorian,
  startOfYear as startOfYearGregorian,
  endOfYear as endOfYearGregorian,
  getYear as getYearGregorian,
  setYear as setYearGregorian,
  addYears as addYearsGregorian,
  addMonths as addMonthsGregorian
} from "date-fns";

export const set = [setJalaali, setGregorian];
export const endOfMonth = [endOfMonthJalaali, endOfMonthGregorian];
export const startOfWeek = [startOfWeekJalaali, startOfWeekGregorian];
export const endOfWeek = [endOfWeekJalaali, endOfWeekGregorian];
export const differenceInCalendarDays = [
  differenceInCalendarDaysJalaali,
  differenceInCalendarDaysGregorian
];
export const isSameDay = [isSameDayJalaali, isSameDayGregorian];
export const getDate = [getDateJalaali, getDateGregorian];
export const startOfDay = [startOfDayJalaali, startOfDayGregorian];
export const endOfDay = [endOfDayJalaali, endOfDayGregorian];
export const isSameMonth = [isSameMonthJalaali, isSameMonthGregorian];
export const addDays = [addDaysJalaali, addDaysGregorian];
export const format = [formatJalaali, formatGregorian];
export const setMonth = [setMonthJalaali, setMonthGregorian];
export const getMonth = [getMonthJalaali, getMonthGregorian];
export const startOfMonth = [startOfMonthJalaali, startOfMonthGregorian];
export const startOfYear = [startOfYearJalaali, startOfYearGregorian];
export const endOfYear = [endOfYearJalaali, endOfYearGregorian];
export const getYear = [getYearJalaali, getYearGregorian];
export const setYear = [setYearJalaali, setYearGregorian];
export const addYears = [addYearsJalaali, addYearsGregorian];
export const addMonths = [addMonthsJalaali, addMonthsGregorian];
