<template>
  <div class="white--text fill-height d-flex flex-column">
    <transition
      enter-active-class="animated fadeInDown"
      leave-active-class="animated fadeOutUp"
      mode="out-in"
    >
      <h2 :key="inputDate.getTime()" class="text-center animation-speed-4 mt-4">
        {{ inputDate | jMoment("jDD jMMMM jYYYY") | persianNumber() }}
      </h2>
    </transition>
    <transition
      enter-active-class="animated fadeInUp"
      leave-active-class="animated fadeOutDown"
      mode="out-in"
    >
      <small
        :key="inputDate.getTime()"
        class="text-center d-block animation-speed-4"
      >
        {{ inputDate | jMoment("DD MMMM YYYY") | persianNumber() }}
      </small>
    </transition>
    <div class="mt-8 mx-4 d-flex justify-space-between">
      <!-- <v-btn icon color="white">
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->
      <div></div>
      <v-btn text color="white" v-if="hasAddAccess" @click="openAdd">
        <transition
          enter-active-class="animated fadeInDown"
          leave-active-class="animated fadeOutUp"
          mode="out-in"
        >
          <span :key="addPanel" class="animation-speed-5">
            <v-icon class="ml-2">
              {{ addPanel ? "mdi-close" : "mdi-plus" }}
            </v-icon>
            <span>
              {{
                addPanel
                  ? $vuetify.lang.t(`$vuetify.pages.calendar.cancel`)
                  : $vuetify.lang.t(`$vuetify.pages.calendar.addEvent`)
              }}
            </span>
          </span>
        </transition>
      </v-btn>
    </div>
    <div class="overflow-y-auto overflow-x-hidden flex-fill px-4">
      <v-expand-transition v-if="isMobile">
        <add-form
          v-if="addPanel"
          :fields="fields"
          :value="editedItem"
          :loading="loading"
          :isMobile="isMobile"
          :isEdit="editedIndex > -1"
          @submit="addEvent"
          @close="addPanel = false"
          class="mt-5"
        />
      </v-expand-transition>
      <v-dialog
        scrollable
        v-else
        v-model="addPanel"
        transition="dialog-bottom-transition"
        max-width="800"
      >
        <add-form
          v-if="addPanel"
          :fields="fields"
          :value="editedItem"
          :loading="loading"
          :isMobile="isMobile"
          :isEdit="editedIndex > -1"
          @submit="addEvent"
          @close="addPanel = false"
        />
      </v-dialog>
      <transition
        enter-active-class="animated fadeInRight"
        leave-active-class="animated fadeOutLeft"
        mode="out-in"
      >
        <div class="mt-4 animation-speed-4" :key="inputDate.getTime()">
          <template v-for="event in events">
            <event-card
              :key="event._id"
              :event="event"
              :selectedEvent="selectedEvent"
              @edit="editEvent"
              @delete="deleteEvent"
            />
          </template>
        </div>
      </transition>
    </div>
    <v-dialog :value="confirmDialogOpen" persistent max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{
            confirmDialog === 0
              ? $vuetify.lang.t(`$vuetify.pages.calendar.editRepeativeEvent`)
              : hasRepeat
              ? $vuetify.lang.t(`$vuetify.pages.calendar.deleteRepeativeEvent`)
              : $vuetify.lang.t(`$vuetify.delete`)
          }}
        </v-card-title>
        <v-card-text>
          <span v-if="!hasRepeat">{{
            $vuetify.lang.t(`$vuetify.pages.calendar.deleteNonRepeativeEvent`)
          }}</span>
          <v-radio-group v-else v-model="deleteType">
            <v-radio
              v-for="dt in deleteTypes"
              :key="dt"
              :label="
                $vuetify.lang.t(`$vuetify.pages.calendar.deleteTypes.${dt}`)
              "
              :value="dt"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="confirmDialog = -1">
            {{ $vuetify.lang.t(`$vuetify.cancel`) }}
          </v-btn>
          <v-btn :loading="loading" color="primary" text @click="apply">
            {{ $vuetify.lang.t(`$vuetify.apply`) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import model from "@/models/eventAndEventSource";
import eventCard from "./eventCard";
import { calendarService } from "@/services/calendar";
import { getValue, getValueOfObj } from "@/tools/functions";
import AddForm from "./addForm.vue";
export default {
  props: {
    inputDate: {
      type: Date,
      required: true
    },
    events: {
      type: Array
    },
    selectedEvent: {
      type: String
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      deleteTypes: ["this", "all", "fromNow", "thisAndFollow"],
      addPanel: false,
      deleteType: "this",
      editedIndex: -1,
      loading: false,
      editedItem: {},
      hasRepeat: false,
      confirmDialog: -1
    };
  },
  computed: {
    score() {
      return this.editedItem.score;
    },
    confirmDialogOpen() {
      return this.confirmDialog > -1;
    },
    hasAddAccess() {
      return ["admin", "operator"].includes(
        this.$store.getters["auth/getUserRole"]
      );
    },
    fields() {
      return Reflect.ownKeys(model.schema)
        .sort(
          (a, b) =>
            (model.schema[a].order || Infinity) -
            (model.schema[b].order || Infinity)
        )
        .filter(
          key =>
            key !== "__ob__" &&
            !getValue(model.schema[key].hide, this.editedItem)
        )
        .map(key => ({
          text: this.$vuetify.lang.t(
            `$vuetify.${model.i18nPath}.${model.schema[key].name || key}`
          ),
          value: key,
          ...getValueOfObj(model.schema[key], this.editedItem)
        }));
    }
  },
  methods: {
    editEvent(item) {
      this.editedIndex = this.events.indexOf(item);
      this.editedItem = {
        ...item,
        duration: item.allDays,
        end: item.eventSourceEnd && new Date(item.eventSourceEnd)
      };
      this.hasRepeat = item.repetitiveType !== "none";
      this.addPanel = true;
    },
    deleteEvent(item) {
      this.editedIndex = this.events.indexOf(item);
      this.editedItem = { ...item, duration: item.allDays };
      this.hasRepeat = item.repetitiveType !== "none";
      this.confirmDialog = 1;
    },
    apply() {
      this.loading = true;
      if (this.confirmDialog === 0) {
        calendarService
          .editEvent(this.editedItem._id, this.deleteType, this.editedItem)
          .then(() => {
            this.editedItem = {
              start: this.inputDate
            };
            this.$emit("change");
            this.addPanel = false;
          })
          .finally(() => {
            this.loading = false;
            this.confirmDialog = -1;
          });
      } else {
        calendarService
          .deleteEvent(this.editedItem._id, this.deleteType)
          .then(() => {
            this.editedItem = {
              start: this.inputDate
            };
            this.$emit("change");
          })
          .finally(() => {
            this.loading = false;
            this.confirmDialog = -1;
          });
      }
    },
    addEvent(editedItem) {
      this.editedItem = editedItem;
      if (this.editedIndex === -1) {
        this.loading = true;
        calendarService
          .addEvents(this.editedItem)
          .then(() => {
            this.editedItem = {
              start: this.inputDate
            };
            this.$emit("change");
            this.addPanel = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.hasRepeat) {
        this.confirmDialog = 0;
      } else {
        calendarService
          .editEvent(this.editedItem._id, "this", this.editedItem)
          .then(() => {
            this.editedItem = {
              start: this.inputDate
            };
            this.addPanel = false;
            this.$emit("change");
          })
          .finally(() => {
            this.loading = false;
            this.confirmDialog = -1;
          });
      }
    },
    openAdd() {
      this.hasRepeat = false;
      this.editedIndex = -1;
      this.editedItem = {
        ...Reflect.ownKeys(model.schema).reduce(
          (obj, key) => ({
            ...obj,
            [key]: getValue(model.schema[key].default, obj)
          }),
          {}
        ),
        start: this.inputDate
      };
      this.addPanel = !this.addPanel;
    }
  },
  components: {
    AddForm,
    eventCard
  },
  watch: {
    inputDate(val) {
      if (this.editedIndex > -1) {
        this.addPanel = false;
      }
      this.editedItem = { ...this.editedItem, start: val };
    },
    score() {
      this.editedItem.color = this.fields.find(
        item => item.value === "color"
      ).default;
    }
  }
};
</script>

<style lang="scss" scoped></style>
