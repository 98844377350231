<template>
  <div class="wrapper" :data-open="state === 'open' ? 1 : 0">
    <div class="bg" @click="() => setState('half')"></div>
    <div
      ref="card"
      class="card"
      :data-state="isMove ? 'move' : state"
      :style="{ top: `${isMove ? y : calcY()}px`, backgroundColor }"
    >
      <div class="pan-area" ref="pan">
        <div class="bar" ref="bar" :style="{ backgroundColor: barColor }"></div>
      </div>
      <div class="contents">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Hammer from "hammerjs";
export default {
  props: {
    openY: {
      type: Number,
      default: 56
    },
    halfY: {
      type: Number,
      default: 120
    },
    defaultState: {
      type: String,
      default: "half"
    },
    barColor: {
      type: String,
      default: "rgba(0, 0, 0, .3)"
    },
    backgroundColor: {
      type: String,
      default: "#FFFFFF"
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mc: null,
      y: 0,
      startY: 0,
      isMove: false,
      state: this.defaultState,
      windowHeight: window.innerHeight
    };
  },
  mounted() {
    window.onresize = () => {
      this.windowHeight = window.innerHeight;
    };
    this.mc = new Hammer(this.$refs.pan);
    this.mc.get("pan").set({ direction: Hammer.DIRECTION_ALL });
    this.mc.on("panup pandown", evt => {
      if (this.openY <= evt.center.y - 16) {
        this.y = evt.center.y - 16;
      } else {
        this.y = this.openY;
      }
    });
    this.mc.on("panstart", evt => {
      this.startY = evt.center.y;
      this.isMove = true;
    });
    this.mc.on("panend", evt => {
      this.isMove = false;
      switch (this.state) {
        case "close": //Added a close state on the condition to be able to swipe from closed to half/closed state.
        case "half":
          if (this.state == "close") {
            if (this.startY - evt.center.y > 120) {
              this.state = "half";
            }
            if (this.startY - evt.center.y > 320) {
              this.state = "open";
            }
            break;
          }
          if (this.startY - evt.center.y > 120) {
            this.state = "open";
          }
          if (this.startY - evt.center.y < -50) {
            this.state = this.closable ? "close" : "half";
          }
          break;
        case "open":
          if (this.startY - evt.center.y < -120) {
            this.state = "half";
          }
          break;
      }
    });
  },
  beforeDestroy() {
    this.mc.destroy();
    window.onresize = null;
  },
  methods: {
    calcY() {
      switch (this.state) {
        case "close":
          return this.windowHeight;
        case "open":
          return this.openY;
        case "half":
          return this.windowHeight - this.halfY;
        default:
          return this.y;
      }
    },
    setState(state) {
      this.state = state;
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  z-index: 8;

  &[data-open="1"] {
    position: fixed;
    top: 0;
    left: 0;
    & .bg {
      display: block;
      transition: all 0.3s;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
.card {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: white;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 -7px 12px rgba(0, 0, 0, 0.2);
  left: 0;
  &[data-state="half"],
  &[data-state="open"],
  &[data-state="close"] {
    transition: top 0.3s ease-out, box-shadow 0.3s ease-out,
      border-radius 0.3s ease-out;
  }
  &[data-state="close"] {
    box-shadow: none;
  }
  &[data-state="open"] {
    border-radius: 0;
    box-shadow: none;
  }
}
.bar {
  width: 45px;
  height: 8px;
  border-radius: 14px;
  margin: 0 auto;
  cursor: pointer;
}
// Moved the pan area above the card content to be ale to swipe from closed state to half/open
.pan-area {
  padding: 12px 0;
  .bar {
    &:hover {
      cursor: grab;
    }
    &:active {
      cursor: grabbing;
    }
  }
}
.contents {
  overflow-y: auto;
  max-height: 100%;
  padding-bottom: calc(100vh * 0.2);
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
}
</style>
