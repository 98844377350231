<template>
  <div
    ref="month"
    :class="[
      'd-flex flex-column month-view flex-fill overflow-hidden animation-speed-4',
      { loading }
    ]"
  >
    <div class="loading">
      <div style="position: absolute; width: 100%">
        <v-progress-linear indeterminate v-if="loading" />
      </div>
    </div>
    <div class="text-center text-caption" v-if="!isSingle">{{ title }}</div>
    <div class="d-flex" style="z-index: 1">
      <div
        v-for="i in 7"
        :key="i"
        :class="['calendar-cell', 'column-title', { isSingle }]"
      >
        {{
          $vuetify.lang.t(
            `$vuetify.weekDays.${calendarType}.${weekDayType}.${i - 1}`
          )
        }}
      </div>
    </div>
    <div
      v-touch="
        isSingle
          ? {
              left: goPrevious,
              right: goNext
            }
          : {}
      "
    >
      <div v-for="row in rows" :key="row" class="d-flex flex-fill">
        <div
          v-for="i in 7"
          :key="id + i"
          :class="[
            'calendar-cell',
            'd-flex',
            'flex-column',
            { outside: isOutSide(i, row - 1) }
          ]"
        >
          <div class="d-flex justify-center">
            <v-btn
              fab
              x-small
              elevation="0"
              :color="colorOfDayButton(i, row - 1)"
              @click="clickOnDay(i, row - 1)"
            >
              {{ dayLabel(i, row - 1) | persianNumber() }}
            </v-btn>
          </div>
          <template
            v-if="visibleEvents && (isSingle || !isOutSide(i, row - 1))"
          >
            <eventView
              v-for="(event, index) in calcEvents(i, row - 1)"
              :key="index"
              :index="index"
              :event="event"
              :tooltipFlag="event && tooltips[event._id]"
              :deleteMode="deleteMode"
              :selectedEvents="selectedEvents"
              :idx="i"
              :row="row"
              :id="id"
              @gotoEvent="gotoEvent"
              @clickOnMore="clickOnMore"
              @showTooltip="showTooltip"
              @hideTooltip="hideTooltip"
            >
            </eventView>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  set,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInCalendarDays,
  isSameDay,
  getDate,
  startOfDay,
  endOfDay,
  isSameMonth,
  addDays,
  addYears,
  addMonths,
  format
} from "@/tools/date-library";
import eventView from "./event.vue";
import { deepEqual } from "fast-equals";
import { calendarService } from "@/services/calendar";
import Queue from "@/tools/queue";
// import md5 from "md5";
let eventPosition = [];
const calcEventsObj = {};
export default {
  props: {
    month: {
      type: Number,
      requierd: true,
      validator: function(value) {
        return value >= 0 && value < 12;
      }
    },
    year: {
      type: Number,
      required: true
    },
    calendarType: {
      type: Number,
      default: 0
    },
    id: {
      type: String,
      required: true
    },
    inputDate: {
      type: Date,
      required: true
    },
    isSingle: {
      type: Boolean,
      default: true
    },
    selectedEvents: {
      type: Array,
      requried: true
    },
    deleteMode: {
      type: Boolean,
      default: false
    },
    query: Array,
    calnedarView: Number
  },
  data: function() {
    return {
      tooltips: {},
      weekDayType: "full",
      visibleEvents: false,
      allEvents: [],
      loading: true
    };
  },
  computed: {
    start() {
      return set[this.calendarType](this.inputDate, {
        year: this.year,
        month: this.month,
        date: 1,
        hours: 0,
        minutes: 0,
        seconds: 0,
        milliseconds: 0
      });
    },
    title() {
      return format[this.calendarType](this.start, "LLLL");
    },
    end() {
      return endOfMonth[this.calendarType](this.start);
    },
    startInView() {
      return startOfWeek[this.calendarType](this.start);
    },
    endInView() {
      return endOfWeek[this.calendarType](this.end);
    },
    duration() {
      return (
        differenceInCalendarDays[this.calendarType](
          this.endInView,
          this.startInView
        ) + 1
      );
    },
    rows() {
      return Math.ceil(this.duration / 7);
    }
  },
  components: {
    eventView
  },
  watch: {
    query: {
      handler: function() {
        this.getEvents();
      },
      deep: true
    }
  },
  methods: {
    isVisible() {
      if (this.$refs.month) {
        return (
          this.visibleEvents ||
          (this.$refs.month.getBoundingClientRect().top > 0 &&
            this.$refs.month.getBoundingClientRect().top <
              window.innerHeight) ||
          (this.$refs.month.getBoundingClientRect().bottom <
            window.innerHeight &&
            this.$refs.month.getBoundingClientRect().bottom > 0)
        );
      }
      return false;
    },
    handleScroll() {
      this.visibleEvents = this.isVisible();
      this.getEvents();
      if (this.visibleEvents) {
        window.removeEventListener("scroll", this.handleScroll);
      }
      this.$forceUpdate();
    },
    showTooltip(event) {
      this.tooltips = { ...this.tooltips, [event._id]: true };
    },
    hideTooltip(event, e) {
      if (e.toElement) {
        const closest =
          e.toElement.closest(`[data-event-id="${event._id}"]`) ||
          e.toElement.closest(`[data-event-id-extend="${event._id}"]`);
        if (!closest) this.tooltips = { ...this.tooltips, [event._id]: false };
      } else {
        this.tooltips = { ...this.tooltips, [event._id]: false };
      }
    },
    goPrevious() {
      this.$emit("goPrevious");
    },
    goNext() {
      this.$emit("goNext");
    },
    calcDay(column, row) {
      const number = column + row * 7 - 1;
      return addDays[this.calendarType](this.startInView, number);
    },
    isOutSide(column, row) {
      const currentDate = this.calcDay(column, row);
      return currentDate < this.start || currentDate > this.end;
    },
    colorOfDayButton(column, row) {
      return isSameDay[this.calendarType](
        this.inputDate,
        this.calcDay(column, row)
      )
        ? "primary"
        : "transparent";
    },
    dayLabel(column, row) {
      return getDate[this.calendarType](this.calcDay(column, row));
    },
    calcEvents(column, row) {
      const currentDate = this.calcDay(column, row);
      let _events;
      if (!calcEventsObj[currentDate]) calcEventsObj[currentDate] = [];
      const events = this.allEvents
        .filter(
          event =>
            startOfDay[this.calendarType](event.start) <=
              startOfDay[this.calendarType](currentDate) &&
            endOfDay[this.calendarType](event.end) >=
              endOfDay[this.calendarType](currentDate)
        )
        .map((event, index, array) => ({
          ...event,
          allDays:
            differenceInCalendarDays[this.calendarType](
              endOfDay[this.calendarType](event.end),
              startOfDay[this.calendarType](event.start)
            ) + 1,
          days:
            differenceInCalendarDays[this.calendarType](
              endOfDay[this.calendarType](event.end),
              startOfDay[this.calendarType](currentDate)
            ) + 1,
          fromPrevious: !isSameDay[this.calendarType](event.start, currentDate),
          arrayLength: array.length
        }))
        .sort((a, b) => {
          const res = Math.abs(b.score) - Math.abs(a.score);
          return res === 0 ? b.allDays - a.allDays : res;
        });

      if (column === 1) {
        eventPosition = events.map(event => event._id);
        _events = [...events];
      } else {
        _events = new Array(events.length).fill();
        const resEvents = [];
        events.forEach(event => {
          const prevIndex = eventPosition.findIndex(id => id === event._id);
          if (prevIndex > -1) {
            _events.splice(prevIndex, 1, event);
          } else {
            resEvents.push(event);
          }
        });
        _events = _events.map(event => event || resEvents.shift());
        eventPosition = _events.map(event => event && event._id);
      }
      if (!deepEqual(calcEventsObj[currentDate], events)) {
        calcEventsObj[currentDate] = events;
        this.$emit("updateSortedEvents", currentDate.getTime(), events);
      }

      return _events;
    },
    clickOnMore(column, row) {
      this.$emit("clickOnMore", this.calcDay(column, row));
    },
    clickOnDay(column, row) {
      this.$emit("clickOnDay", this.calcDay(column, row));
    },
    gotoEvent(column, row, id) {
      this.$emit("clickOnDay", this.calcDay(column, row));
      this.$emit("gotoEvent", id);
    },
    async getEvents() {
      this.loading = true;
      const q = new Queue("getEvents");
      const qn = new Queue("getEvents-Next");
      try {
        const res = await q.exec(
          calendarService.getEvents,
          this.query,
          this.start.getTime(),
          this.end.getTime()
        );
        this.allEvents = res.data.items.map(event => ({
          ...event,
          start: new Date(event.start),
          end: new Date(event.end)
        }));
      } catch (err) {
        console.error(err);
      }
      this.loading = false;
      for (let i = -2; i < 2; i++) {
        if (i === 0) continue;
        switch (this.calnedarView) {
          case 2:
            qn.exec(
              calendarService.getEvents,
              this.query,
              addYears[this.calendarType](this.start, i).getTime(),
              addYears[this.calendarType](this.end, i).getTime()
            );
            break;
          case 1:
            qn.exec(
              calendarService.getEvents,
              this.query,
              addMonths[this.calendarType](this.start, 3 * i).getTime(),
              addMonths[this.calendarType](this.end, 3 * i).getTime()
            );
            break;
          default:
            qn.exec(
              calendarService.getEvents,
              this.query,
              addMonths[this.calendarType](this.start, i).getTime(),
              addMonths[this.calendarType](this.end, i).getTime()
            );
        }
      }
    }
  },
  created() {
    if (isSameMonth[this.calendarType](this.inputDate, this.start)) {
      const diff = differenceInCalendarDays[this.calendarType](
        this.inputDate,
        this.startInView
      );
      this.clickOnDay((diff % 7) + 1, Math.floor(diff / 7));
    }
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.handleScroll();
  }
};
</script>

<style lang="scss" scoped>
.calendar-cell {
  $borderColor: #eaf0f4;
  width: 14.2857142857%;
  height: 120px;
  border-bottom: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  &:last-of-type {
    border-left: 1px solid $borderColor;
  }
  &.outside {
    border: none;
    background: #ededed;
  }
  &.column-title {
    background: #f5f6fa;
    font-size: 12px;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 9px;
    }
    &.isSingle {
      font-size: 12px;
      @media #{map-get($display-breakpoints, 'lg-and-up')} {
        font-size: 16px;
      }
    }
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-bottom: 1px solid $borderColor;
    border-top: 1px solid $borderColor;
    &:first-of-type {
      border-right: 1px solid $borderColor;
    }
    &:last-of-type {
      border-left: 1px solid $borderColor;
    }
  }
}
.month-view.loading {
  opacity: 0.5;
  pointer-events: none;
}
.loading {
  position: relative;
  z-index: 2;
  opacity: 0.6;
}
</style>
